import firebase from 'firebase/app';
import 'firebase/functions';
import ReCaptcha from 'react-recaptcha';
import Swal from 'sweetalert2';

import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useHistory } from "react-router-dom";
import {
  // Circle,
  // CircleMarker,
  Map,
  Marker,
  // Polygon,
  Popup,
  TileLayer,
} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import './Location.scss';
import MapMarkerColor from '../components/MapMarkerColor';
import Page from '../components/Page';
import PageProgress from '../components/PageProgress';
import LoadingOverlay from '../components/LoadingOverlay';

L.Icon.Default.imagePath='images/'

type Position = [number, number];
type Viewport = {
  center: [number, number],
  zoom: number,
}

const center: Position = [1.3521, 103.8198];
const defaultViewport = {
  center,
  zoom: 11,
}

const singaporePoly: Position[] = [[1.4758232098617243,103.80294799804689],[1.4710183086975963,103.84140014648439],[1.4342058720517324,103.88397216796876],[1.4247144463111866,103.91191748046876],[1.4315428390435285,103.92791748046876],[1.420216730913803,103.96224975585939],[1.4229624592359549,103.99864196777344],[1.4480170776534103,104.03091430664064],[1.4315428390435285,104.07520294189455],[1.395161809320074,104.09751892089845],[1.355347959523639,104.0559768676758],[1.3086688163692952,104.0401840209961],[1.2949394890063037,104.00447845458986],[1.304550026058009,103.96980285644533],[1.2788074338728292,103.89839172363283],[1.2472295001224183,103.85890960693361],[1.2183971406297442,103.86783599853517],[1.2036375979480467,103.84929656982422],[1.193683442339846,103.80054473876955],[1.156269225449259,103.74183654785158],[1.1658802649333015,103.7157440185547],[1.210845756798474,103.68896484375001],[1.2012348740669747,103.64639282226564],[1.2105025115751686,103.59764099121094],[1.288761267252458,103.60622406005861],[1.3165631454404798,103.60244750976564],[1.3800600828999807,103.65085601806642],[1.4078609129860529,103.66218566894531],[1.41438204735208,103.66836547851564],[1.428110690981957,103.67385864257814],[1.4363478376531729,103.68278503417969],[1.4384071196654367,103.69480133056642],[1.4517924071051906,103.71059417724611],[1.453851675046159,103.72879028320314],[1.4428688909484388,103.74526977539062],[1.445957804379046,103.7596893310547],[1.4535084638536693,103.77651214599611]];

// const poly = poly2.map((pos: Position) => [pos[0]-0, pos[1]]);
// console.log(JSON.stringify(poly));

// function isMarkerInsidePolygonArray(position: Position, polyPoints) {
//   // var polyPoints = poly.getLatLngs();
//   var x = position[0], y = position[1];
//
//   var inside = false;
//   for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
//     var xi = polyPoints[i].lat, yi = polyPoints[i].lng;
//     var xj = polyPoints[j].lat, yj = polyPoints[j].lng;
//
//     var intersect = ((yi > y) !== (yj > y))
//       && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
//     if (intersect) inside = !inside;
//   }
//
//   return inside;
// }

function isMarkerInsidePolygonPoints(position: Position, polyPoints) {
  var x = position[0], y = position[1];

  var inside = false;
  for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
    var xi = polyPoints[i][0], yi = polyPoints[i][1];
    var xj = polyPoints[j][0], yj = polyPoints[j][1];

    var intersect = ((yi > y) !== (yj > y))
      && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }

  return inside;
}

function isMarkerInsideSingapore(position) {
  return isMarkerInsidePolygonPoints(position, singaporePoly);
}

const Location = (props) => {
  const [viewport, setViewport] = useState<Viewport>(defaultViewport);
  const [previousViewport, setPreviousViewport] = useState<Viewport>(defaultViewport);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string>("");
  const mapRef = useRef<Map>();
  const history = useHistory();

  const captchaRef = useRef<ReCaptcha>();

  const isSameCenter = (newCenter: Position, center: Position) => {
    return newCenter[0] === center[0] && newCenter[1] === center[1];
  }

  const isDefaultCenter = () => {
    return isSameCenter(viewport.center, defaultViewport.center);
  }

  const executeCaptcha = () => {
    console.log('executeCaptcha');
    console.log('[executeCaptcha] viewport:', viewport);
    captchaRef.current.execute();
  };

  const onLoadRecaptcha = () => {
    console.log('[onLoadRecaptcha] viewport:', viewport);
    console.log('Recaptcha loaded.');
  }

  const verifyCallback = async (recaptchaToken) => {
    console.log('[verifyCallback] viewport:', viewport);
    console.log('[verifyCallback] recaptchaToken:', recaptchaToken);
    setRecaptchaToken(recaptchaToken);
  }

  useEffect(() => {
    if (!props.name) {
      history.push('/');
      return;
    }

    if (!props.message) {
      history.push('/message');
      return;
    }

    if (!props.selectedColor) {
      history.push('/colours');
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!recaptchaToken) {
      return;
    }

    handleSubmitPart2(recaptchaToken);
  }, [recaptchaToken]);

  const onViewportChange = (newViewport: Viewport) => {
    setViewport(newViewport);
  }

  const onViewportChanged = (newViewport: Viewport) => {
    // singaporePoly.push(newViewport.center);
    // console.log('singaporePoly:', singaporePoly);
    const isInSingapore = isMarkerInsideSingapore(newViewport.center);
    // console.log('isInSingapore:', isInSingapore);
    if (isInSingapore) {
      setPreviousViewport(newViewport);
      return;
    }

    if (!previousViewport) {
      // Will not reach this as there is a default viewport.
      return;
    }

    // Roll back to previous viewport.
    setViewport(previousViewport);
    if (mapRef && mapRef.current && mapRef.current!.leafletElement) {
      mapRef.current!.leafletElement.setView(L.latLng(previousViewport.center[0], previousViewport.center[1]), previousViewport.zoom);
    }
  }

  const customIcon = L.divIcon({
    className: 'custom icon',
    html: ReactDOMServer.renderToString(<MapMarkerColor  color={props.selectedColor} dotColor={"#FFEEF3"}  />),
    iconSize: L.point(30, 30),
  });

  // This writes directly to firestore, but we're using a cloud function to verify captcha before writing now.
  // const handleSubmitLegacy = async () => {
  //   const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  //   const data = {
  //     name: props.name,
  //     message: props.message,
  //     color: props.selectedColor,
  //     location: viewport.center,
  //     created: timestamp,
  //     recaptchaToken: props.recaptchaToken,
  //   };
  //   console.log('data to send to Firebase:', data);
  //
  //   await firebase.firestore().collection("checkins").doc().set(data);
  //   console.log('Saved');
  //   history.push('/thank-you');
  //   props.clearInputs && props.clearInputs();
  // };

  const handleSubmit = async () => {
    console.log('[handleSubmit] viewport:', viewport);
    setSubmitting(true);
    executeCaptcha();
  }

  const handleSubmitPart2 = async (recaptchaToken: string) => {
    console.log('[handleSubmitPart2] viewport:', viewport);
    const addDotFunction = firebase.functions().httpsCallable('addDot');

    const data = {
      recaptchaToken: recaptchaToken,
      dotData: {
        name: ((props.name || "") as string).trim(),
        message: ((props.message || "") as string).trim(),
        color: props.selectedColor,
        location: viewport.center,
      }
    };
    console.log('data:', data);

    const result: any = await addDotFunction(data);
    console.log('result:', result);
    if (result.data.isError) {
      setSubmitting(false);
      captchaRef.current.reset();
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Please try again',
      });
      return;
    }
    history.push('/thank-you');
    props.clearInputs && props.clearInputs();
  };

  return (
    <Page className={"location-screen"}>
      <h2>
        <PageProgress currentScreen={2} totalScreens={3} />
        We’re nearly done!<br/>
        Your message has been saved. Now please select your digital ‘light-up’ location.
      </h2>
      <div className={"map"}>

        <Map
          center={center}
          zoom={11}
          style={{ height: '345px' }}
          ref={mapRef}

          onViewportChange={onViewportChange}
          onViewportChanged={onViewportChanged}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
          />
          {/*<TileLayer*/}
          {/*  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attributions">CARTO</a>'*/}
          {/*  url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png"*/}
          {/*/>*/}
          {/*<TileLayer*/}
          {/*  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attributions">CARTO</a>'*/}
          {/*  url="https://{s}.basemaps.cartocdn.com/rastertiles/dark_nolabels/{z}/{x}/{y}.png"*/}
          {/*/>*/}
          <Marker position={viewport.center} draggable={false} icon={customIcon} >
            <Popup>You are here</Popup>
          </Marker>
          {/*<CircleMarker center={center} color="pink" radius={20}>*/}
          {/*  <Popup>You are here</Popup>*/}
          {/*</CircleMarker>*/}
          {/*<Polygon positions={singaporePoly} />*/}
        </Map>

      </div>

      <div className={"note"}>We recommend choosing a spot close to where you are physically in Singapore.</div>

      <div className={"actions"}>
        {isSubmitting && (
          <button disabled={true}>Please hold on</button>
        )}
        {!isSubmitting && !isDefaultCenter() && (
          <button onClick={handleSubmit}>Submit</button>
        )}
        {isDefaultCenter() && (
          <button disabled={true}>Oops, you haven't moved your pin yet</button>
        )}
        {/*{props.isDebugMode && (<div>{viewport.center[0]},{viewport.center[0]} </div>)}*/}
      </div>
      {isSubmitting && <LoadingOverlay />}
      <ReCaptcha
        ref={captchaRef}
        sitekey="6LfJev4UAAAAAETvueGhzDy60OD5Y9V5mhNj7ePk"
        size="invisible"
        verifyCallback={verifyCallback}
        onloadCallback={onLoadRecaptcha}
      />
    </Page>
  )
};

export default Location;
