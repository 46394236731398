import React  from 'react';
import './Hello.scss';
import { Link } from "react-router-dom";
import Page from '../components/Page';

const Hello = (props) => {

  return (
    <Page
      className={"hello-screen"}
      topContent={
        <div className={"top"}>
          <img className={"mascot"} src={"/images/mascot.png"} alt="mascot" />
          <img className={"logo"} src={"/images/pd2020logo.png"} alt="logo" />
        </div>
      }
    >
      <div className={"welcome-message center"}>
        <h3 className={"mb16"}>READY TO MAKE PINK DOT?</h3>
        <div className={"small mb16"}>
          Thank you for joining us this year!<br/>
          Even though we can’t be together in Hong Lim Park this year, let’s stand up, be counted and continue supporting LGBTQ rights in Singapore.
        </div>
        {/*<div className={"smallest mb16"}>*/}
        {/*  Apart from lighting up our homes and workplaces in PINK, we’re also going to make history by forming Singapore’s very first digital Pink Dot with a massive ONLINE LIGHTUP.*/}
        {/*</div>*/}

        <div className={"summary"}>
          {/*<h3 className={"mb16"}>So join in now. It’s as simple as…</h3>*/}

          <div className={"summary__row"}>
            <div className={"summary__bullet"}>1</div>
            Submit your preferred name
          </div>
          <div className={"summary__row"}>
            <div className={"summary__bullet"}>2</div>
            Compose a message of love
            and support for Singapore
          </div>
          <div className={"summary__row"}>
            <div className={"summary__bullet"}>3</div>
            Select a location (in Singapore)
            that best represents where you
            will be participating from
          </div>

        </div>

        <div className={"actions"}>
          <Link to={"/name"}>
            <button>Let's start</button>
          </Link>
          {/*<div className="secret-link-to-map">*/}
          {/*  <Link to={"/map"}>*/}
          {/*    <span>&nbsp;</span>*/}
          {/*  </Link>*/}
          {/*</div>*/}
        </div>
      </div>
    </Page>
  )
}

export default Hello;
