import React, {useCallback, useEffect, useRef, useState} from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from "use-supercluster";
import rawItemsInBatches from '../assets/map/data/approved_20200711_1305.json';
import {PointFeature} from "supercluster";
import {useHistory, useParams} from "react-router-dom";

import customMapStyle from '../assets/googleMapStyle';
import './Map.scss';
import {Bounds, Dot, LatLng, MapPosition} from "../types/map";
import ActiveMarker from "../components/ActiveMarker";
import ClusterMarker from "../components/ClusterMarker";
import Marker from "../components/Marker";

const googleMapsKey = 'AIzaSyDHEtoMuNKUfPfUhcZggd9Z3peAgf-hWgo';

const singaporeCenter: LatLng = {
  lat: 1.3521,
  lng: 103.8198,
};

const defaultZoom = 12;
const minZoom = 11;
const maxZoom = 20;
const defaultBounds: Bounds = [103.58307896118163, 1.2103431881380686, 104.05652103881835, 1.4938485342232042];

let keys: string[] = [];
let itemsByKey: { [key: string]: Dot } = {};
let items: Dot[] = [];
let allHashInitial: { [key: string ]: boolean } = {};
let unseenHashInitial: { [key: string ]: number } = {};
let unseenArrayInitial: string[] = [];

let skipped = 0;

// V2 format:
// {
//   n: data.name,
//   m: data.message,
//   d: `${key}|${lat}|${lng}|${data.color}|${created}`,
// }

// const rawItems = (rawItemsInBatches as any[][]).reduce((acc, current) => [...acc, ...current], []);

const superClusterOptions = { radius: 50, maxZoom: 14, minPoints: 4 };
// const supercluster = new Supercluster(superClusterOptions);
// supercluster.load(pointsInitial);
// const clusters = supercluster.getClusters([103.58307, 1.21034, 104.05652, 1.49384] as GeoJSON.BBox, defaultZoom);

export interface MapScreenProps {
  isDebugMode: boolean;
}

const Map: React.FC<MapScreenProps> = ({ isDebugMode }) => {

  const { dotKey: dotKeyFromUrl } = useParams();

  const history = useHistory();
  const [points, setPoints] = useState<PointFeature<any>[]>([]);
  // const [zoom, setZoom] = useState<number>(defaultZoom);
  // const [center, setCenter] = useState<LatLng>(singaporeCenter);
  const [mapPosition, setMapPosition] = useState<MapPosition>({
    zoom: defaultZoom,
    center: singaporeCenter,
  });

  // const [bounds, setBounds] = useState<Bounds>(defaultBounds);
  const bounds = useRef<Bounds>(defaultBounds);
  // const [clusters, setClusters] = useState<any>(defaultClusters);
  const [selectedDot, setSelectedDotReal] = useState<Dot>();
  const [showClusterCount, setShowClusterCount] = useState<boolean>(false);
  // const [limitDots, setLimitDots] = useState<number>(0);
  const googleMapRef = useRef();
  const googleRef = useRef();
  const dotHistory = useRef<string[]>([]);
  const dotHistoryCursor = useRef<number>(0);
  const seenHash = useRef<{ [key: string ]: boolean }>({});
  const unseenArray = useRef<string[]>(unseenArrayInitial);
  const unseenHash = useRef<{ [key: string ]: number }>(unseenHashInitial);
  const allHash = useRef<{ [key: string ]: boolean }>(allHashInitial);

  const setZoom = (newZoom: number) => {
    if (googleMapRef && googleMapRef.current) {
      // @ts-ignore
      googleMapRef!.current!.setZoom(newZoom);
    }
  }

  const panTo = (newCenter: LatLng) => {
    if (googleMapRef && googleMapRef.current) {
      // @ts-ignore
      googleMapRef?.current?.panTo(newCenter);
    }
  }

  const setCenter = (newCenter: LatLng) => {
    if (googleMapRef && googleMapRef.current) {
      // @ts-ignore
      googleMapRef?.current?.setCenter(newCenter);
    }
  }

  const setSelectedDot = (dot?: Dot, pan: boolean = true) => {
    if (!dot) {
      // @ts-ignore
      googleMapRef?.current?.setZoom(defaultZoom);
      // @ts-ignore
      googleMapRef?.current?.panTo(singaporeCenter);
      setSelectedDotReal(undefined);
      history.push("/");
      return;
    }

    // console.log('mapPosition.zoom:', mapPosition.zoom);
    // if (mapPosition.zoom < 14) {
    //   setZoom(14);
    // }

    if (pan) {
      if (dot && dot.lat && dot.lng) {
        // setCenter( { lat: data.lat, lng: data.lng });
        // setMapPosition({zoom: mapPosition.zoom, center: { lat: data.lat, lng: data.lng }});
        // setCenter({ lat: data.lat, lng: data.lng });

        // If we want to always pan so that active dot is center.
        // panTo({ lat: dot.lat, lng: dot.lng });

        // If we only want to pan if outside of current bounds.
        if (bounds.current) {
          const { lng: x, lat: y } = dot;
          let [ minX, minY, maxX, maxY ] = bounds.current;
          let horizontalOffset = (maxX - minX) / 6;
          let verticalOffset = (maxY - minY) / 6;
          minX += horizontalOffset;
          maxX -= horizontalOffset;
          minY += verticalOffset;
          maxY -= verticalOffset;

          // console.log('topLeftX, bottomRightY, bottomRightX, topLeftY:', [minX, minY, maxX, maxY], 'x ,y:', [x, y]);
          const withinCurrentBounds = minX < x && x < maxX && minY < y && y < maxY;

          if (!withinCurrentBounds) {
            // console.log('Dot is outside current bounds, do pan.');
            panTo({ lat: dot.lat, lng: dot.lng });
          }
        } else {
          panTo({ lat: dot.lat, lng: dot.lng });
        }
      }
    }

    setSelectedDotReal(dot);
    setTimeout(() => {
      history.push(`/dot/${dot.key}`);
    }, 10);
  }

  useEffect(() => {

    // Rest global states.
    keys = [];
    itemsByKey = {};
    items = [];
    allHashInitial = {};
    unseenHashInitial = {};
    unseenArrayInitial = [];

    let rawItems: any[] = [];
    rawItemsInBatches.forEach((dots: any[]) => {
      // console.log(`Loaded ${dots.length} dots`);
      rawItems.push(...dots);
    });

    let i = 0;
    rawItems.forEach((data: any ) => {
      const [key, lat, lng, color, created] = data.d.split("|");

      // if (lat === "1.35210" && lng === "103.81980") {
      //   // This is the default center location, user did not change this.
      //   // @TODO: Consider randomizing this location. For now we're skipping them.
      //   skipped++;
      //   return false;
      // }

      const dot = {
        key,
        name: data.n,
        message: data.m,
        color,
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        created,
      } as Dot;

      keys.push(key);
      itemsByKey[key] = dot;
      items.push(dot);
      allHashInitial[key] = false;
      unseenHashInitial[key] = i;
      unseenArrayInitial.push(key);
      i++;
    })
    console.log(`${skipped} dots skipped for being at default location.`);

    const pointsInitial: PointFeature<any>[] = items.map(item => ({
      type: "Feature",
      properties: { ...item, cluster: false },
      geometry: {
        type: "Point",
        coordinates: [
          item.lng,
          item.lat,
        ],
      },
    } as PointFeature<any>));

    // Set refs.
    dotHistory.current = [];
    dotHistoryCursor.current = 0;
    seenHash.current = {};
    unseenArray.current = unseenArrayInitial;
    unseenHash.current = unseenHashInitial;
    allHash.current = allHashInitial;

    setPoints(pointsInitial);

    if (selectedDot) {
      // Already have previously selected dot? Do not load from url.
      return;
    }

    // console.log('dotKeyFromUrl:', dotKeyFromUrl);
    if (itemsByKey[dotKeyFromUrl]) {
      const selectedDotFromUrl = itemsByKey[dotKeyFromUrl];
      setTimeout(() => {
        // console.log('setSelectedDot on ', selectedDotFromUrl);
        setSelectedDot(selectedDotFromUrl, false);

        setZoom(14);
        setTimeout(() => {
          panTo({ lat: selectedDotFromUrl.lat, lng: selectedDotFromUrl.lng });
        }, 10);

      }, 500);
    }

  }, []);

  // const { clusters, supercluster } = useSupercluster({
  //   points,
  //   bounds,
  //   zoom,
  //   // @ts-ignore
  //   options: superClusterOptions,
  // });

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: bounds.current,
    zoom: mapPosition.zoom,
    // @ts-ignore
    options: superClusterOptions,
  });

  // const clusters = supercluster.getClusters([-180, -85, 180, 85], zoom);
  // const clusters = supercluster.getClusters(bounds as GeoJSON.BBox, zoom);
  // const [west, south, east, north] = bounds.current;
  // const clusters = useMemo(
  //   () => {
  //     console.log("Clusters refreshed");
  //     return supercluster.getClusters(bounds.current as GeoJSON.BBox, zoom);
  //   },
  //   [west, south, east, north, zoom, center]
  // )

  // const clusters = useMemo(
  //   () => {
  //     console.log("Clusters refreshed");
  //     return supercluster.getClusters(bounds.current as GeoJSON.BBox, mapPosition.zoom);
  //   },
  //   [bounds.current, mapPosition.zoom, mapPosition.center]
  // )

  // useEffect(() => {
  //   console.log("Clusters refreshed");
  //   const newClusters = supercluster.getClusters(bounds.current as GeoJSON.BBox, mapPosition.zoom);
  //   setClusters(newClusters);
  // }, [bounds.current, mapPosition.zoom, mapPosition.center]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.style.overflow = 'hidden';
    }

    return () => {
      if (body) {
        body.style.overflow = 'auto';
      }
    }
  }, []);


  const mapOptions = {
    styles: customMapStyle,
    panControl: false,
    mapTypeControl: false,
    zoomControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    scrollwheel: true,
    clickableIcons: false,
    restriction: {
      latLngBounds: { north: 1.51613, south: 1.0621, west: 103.52206, east: 104.15112 },
      strictBounds: false,
    }
  };

  const onMapChange = ({ center, zoom, bounds: newBounds, marginBounds }) => {
    // console.log('onMapChange, center, zoom, bounds, marginBounds:', { center, zoom, newBounds, marginBounds });

    // @ts-ignore
    // setBounds([
    //   bounds.nw.lng,
    //   bounds.se.lat,
    //   bounds.se.lng,
    //   bounds.nw.lat
    // ]);
    bounds.current = [
      newBounds.nw.lng,
      newBounds.se.lat,
      newBounds.se.lng,
      newBounds.nw.lat
    ];

    // setTimeout(() => {
    //   const newClusters = supercluster.getClusters(bounds.current as GeoJSON.BBox, mapPosition.zoom);
    //   setClusters(newClusters);
    // }, 250);

    // setZoom(zoom);
    // setCenter(center);
    setTimeout(() => {
      setMapPosition({zoom, center});
    }, 500);
  }

  const onGoogleApiLoaded = ({map, maps}) => {
    googleMapRef.current = map;
    googleRef.current = maps;
  };

  const handleZoomIn = () => {
    if (mapPosition.zoom >= maxZoom) {
      return;
    }
    // // setZoom(zoom + 1);
    // setMapPosition({zoom: mapPosition.zoom + 1, center: mapPosition.center});

    if (googleMapRef && googleMapRef.current) {
      // @ts-ignore
      googleMapRef!.current!.setZoom(mapPosition.zoom + 1);
    }
  }

  const handleZoomOut = () => {
    if (mapPosition.zoom <= minZoom) {
      return;
    }
    // setZoom(zoom - 1);
    // setMapPosition({zoom: mapPosition.zoom - 1, center: mapPosition.center});

    if (googleMapRef && googleMapRef.current) {
      // @ts-ignore
      googleMapRef!.current!.setZoom(mapPosition.zoom - 1);
    }
  }

  const handleSelectDot = useCallback((key: string) => {
    // Clicking on a dot always disregard history cursor, append to end.
    dotHistoryCursor.current = 0;
    dotHistory.current.push(key);
    console.log('dotHistory.current:', dotHistory.current);
    handleViewDot(key);
  }, []);

  const handleViewDot = (key: string) => {
    console.log('handleViewDot, key:', key);
    console.log('dotHistory.current:', dotHistory.current);
    if (!key) {
      setSelectedDot(undefined);
      return;
    }

    const data = itemsByKey[key];
    setSelectedDot(data);

    allHash.current[key] = true;
    seenHash.current[key] = true;
    delete unseenHash.current[key];
  };

  const handleHome = () => {
    // setZoom(12);
    // setCenter(singaporeCenter);
    // setMapPosition({zoom: defaultZoom, center: singaporeCenter});

    // Clear selected dot if any.
    setSelectedDot(undefined);
  }

  const handleCheckIn = () => {
    history.push('/checkin');
  }

  const handleNextDot = () => {
    console.log('handleNextDot dotHistory:', dotHistory.current, ', cursor: ', dotHistoryCursor.current);
    // Currently viewing history

    // if (dotHistoryCursor.current > 0) {
    //   dotHistoryCursor.current--;
    //   const nextKey = dotHistory.current[dotHistory.current.length - dotHistoryCursor.current];
    //   handleViewDot(nextKey);
    //   return;
    // }

    // Pick one randomly from unseenHash.
    console.log('unseenArray.current:', unseenArray.current);
    const index = Math.floor(Math.random() * unseenArray.current.length);
    const key = unseenArray.current[index];
    dotHistory.current.push(key);
    console.log('handleNextDot dotHistory:', dotHistory.current, ', cursor: ', dotHistoryCursor.current);
    handleViewDot(key);
  }

  const handlePrevDot = () => {
    console.log('handlePrevDot dotHistory:', dotHistory.current, ', cursor: ', dotHistoryCursor.current);
    if (dotHistoryCursor.current === dotHistory.current.length) {
      handleViewDot("");
      return;
    }
    dotHistoryCursor.current++;
    const previousKey = dotHistory.current[dotHistory.current.length - dotHistoryCursor.current];
    console.log('handlePrevDot dotHistory:', dotHistory.current, ', cursor: ', dotHistoryCursor.current);
    handleViewDot(previousKey);
  }

  return (
    <div className="map-screen" style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapsKey }}
        defaultCenter={singaporeCenter}
        defaultZoom={defaultZoom}
        // center={mapPosition.center}
        // zoom={mapPosition.zoom}
        options={mapOptions}
        onChange={onMapChange}
        onGoogleApiLoaded={onGoogleApiLoaded}
        yesIWantToUseGoogleMapApiInternals
        resetBoundsOnResize={true}
      >
        {clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;

          const { properties: info } = cluster;
          const {
            cluster: isCluster,
            point_count: pointCount
          } = info;

          if (!isCluster) {
            return (
              <Marker
                key={info.key}
                dotKey={info.key}
                lat={latitude}
                lng={longitude}
                color={info.color}
                onClick={handleSelectDot}
                zoomLevel={mapPosition.zoom}
                totalPoints={points.length}
              />
            );
          }

          return (
            <ClusterMarker
              key={cluster.id as string}
              dotKey={cluster.id as string}
              lat={latitude}
              lng={longitude}
              zoomLevel={mapPosition.zoom}
              isCluster={isCluster}
              clusterId={cluster.id as number}
              numberOfPoints={pointCount}
              totalPoints={points.length}
              showClusterCount={showClusterCount}
              onClick={() => {
                if (!cluster.id) {
                  return;
                }
                if (!supercluster) {
                  return;
                }
                console.log(cluster, ' type:', typeof cluster);
                console.log(cluster.id, ' type:', typeof cluster.id);
                const expansionZoom = Math.min(
                  supercluster.getClusterExpansionZoom(cluster.id as number),
                  20
                );
                // @ts-ignore
                googleMapRef && googleMapRef.current && googleMapRef!.current!.setZoom(expansionZoom);
                // @ts-ignore
                googleMapRef && googleMapRef.current && googleMapRef!.current!.panTo({ lat: latitude, lng: longitude });
              }}
            />
          );
        })}
        {selectedDot && <ActiveMarker
          dotKey={selectedDot.key}
          zoomLevel={mapPosition.zoom}
          lat={selectedDot?.lat}
          lng={selectedDot?.lng}
        />}
      </GoogleMapReact>
      <div className="messagesBottomOverlay" />
      <div className="messagesBottomContent">
        <div className="message-wrapper">
          {selectedDot ? (
            <div className="message">
              <h3>{selectedDot.name}</h3>
              <p>{selectedDot.message}</p>
            </div>
          ) : (
            <div className="message message--welcome">
              <h3>Welcome to the first digital Pink Dot!</h3>
              <p>Explore the map by clicking on the pink dots, and remember – Love Lives Here. Thank you Singapore.</p>
            </div>
          )}
        </div>
        <div className="brand">
          <div className="left" onClick={handleHome}>View all</div>
          {/*<div className="left" onClick={handleCheckIn}>Sign up</div>*/}
          <div className="logo" onClick={handleHome} />
          <div className="right" onClick={handleNextDot}>Shuffle</div>
        </div>
      </div>
      {/*<div className="mascot" />*/}

      <div className="zoom-controls">
        <div className="zoom-controls__in" style={{ opacity: (mapPosition.zoom >= maxZoom) ? 0.5 : 1 }} onClick={handleZoomIn}><div className="icon" /></div>
        <div className="zoom-controls__out" style={{ opacity: (mapPosition.zoom <= minZoom) ? 0.5 : 1 }} onClick={handleZoomOut}><div className="icon" /></div>
      </div>

      {isDebugMode && (
        <div className="stats">
          Total: {points.length}, drawn: {clusters.length}, zoom: {mapPosition.zoom}, dots at default location: {skipped}, selected: {selectedDot?.key || 0},
          {" "}<button onClick={() => setShowClusterCount(!showClusterCount)}>{showClusterCount ? "hide indicators" : "show indicators"}</button>
        </div>
      )}
    </div>
  );
};

export default Map;
