import React from 'react';

const MapMarkerColor = (props) => {
  return (
    <svg viewBox="0 0 390 512" xmlns="http://www.w3.org/2000/svg">
      {/*<g>*/}
      {/*  <title>background</title>*/}
      {/*  <rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1"/>*/}
      {/*</g>*/}
      <g>
        <path fill={props.color} id="svg_1" d="m195,513l155.90234,-199.87109l0.17578,-0.21875c25.46094,-33.92578 38.92188,-74.35547 38.92188,-116.91016c0,-107.51953 -87.48047,-195 -195,-195s-195,87.48047 -195,195c0,42.55469 13.46094,82.98438 39.09766,117.12891l155.90234,199.87109z"/>
        <ellipse stroke="null" ry="83.499999" rx="83.499999" id="svg_2" cy="195.953124" cx="194.999999" strokeOpacity="null" strokeWidth="null" fill={props.dotColor} />
      </g>
    </svg>
  );
};

export default MapMarkerColor;
