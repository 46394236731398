import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import './ThankYou.scss';
import Page from '../components/Page';

const urlToShare = 'https://loveliveshere.pinkdot.sg/';
const shareText = 'Ready to make Pink Dot? Come join me to form the first digital Pink Dot online on 27 June with a massive online light-up!';

const ThankYou = (props) => {
  const history = useHistory();

  useEffect(() => {
    if (!props.name) {
      history.push('/');
      return;
    }

    if (!props.message) {
      history.push('/message');
      return;
    }

    if (!props.selectedColor) {
      history.push('/colours');
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShareFB = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`, "pop", "width=600, height=400, scrollbars=no");
    return false;
  }

  const getTwitterShareUrl = () => {
    return `http://twitter.com/share?text=${shareText}&url=${urlToShare}&hashtags=pinkdot12,pinkdotsg,loveliveshere`;
  }

  const handleGoToMap = () => {
    history.push('/');
  }

  return (
    <Page
      className={"thankyou-screen"}
      topContent={
        <div className={"top"}>
          <img className={"mascot"} src={"/images/mascot.png"} alt="mascot" />
          <img className={"logo"} src={"/images/pd2020logo.png"} alt="logo" />
        </div>
      }
    >
      <div>
        <div className={"thankyou-message"}>
          And you're done! Thank you for your love and support. Check back here in a while as we update the map with your message.
        </div>
        <button onClick={handleGoToMap} style={{ textTransform: 'uppercase' }}>Read what others wrote</button>
      </div>

      <div className={"sharebox mt24"}>
        <h2 className={"mb8"}>Share this</h2>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={handleShareFB} rel="noopener noreferrer" className={"social-link social-link--fb"}>&nbsp;</a>
        <a href={getTwitterShareUrl()} target="_blank" rel="noopener noreferrer" className={"social-link social-link--tw"}>&nbsp;</a>
      </div>

      <div className={"social-links mt24"}>
        <h3 className={"mb8"}>Learn more about PinkDot SG</h3>

        <a href="https://pinkdot.sg/" target="_blank" rel="noopener noreferrer" className={"social-link social-link--web"}>&nbsp;</a>
        <a href="https://www.facebook.com/pinkdotsg/" target="_blank" rel="noopener noreferrer" className={"social-link social-link--fb"}>&nbsp;</a>
        <a href="https://www.instagram.com/pinkdotsg" target="_blank" rel="noopener noreferrer" className={"social-link social-link--ig"}>&nbsp;</a>
        <a href="https://www.youtube.com/user/pinkdotsg" target="_blank" rel="noopener noreferrer" className={"social-link social-link--yt"}>&nbsp;</a>
        <a href="https://www.twitter.com/pinkdotsg" target="_blank" rel="noopener noreferrer" className={"social-link social-link--tw"}>&nbsp;</a>
      </div>
    </Page>
  );
};

export default ThankYou;
