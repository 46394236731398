import React from 'react';

const LoadingOverlay: React.FC = () => {
  return (
    <div className={"overlay"}>
      <h2 className={"heading"}>Submitting...</h2>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default LoadingOverlay;
