import React from 'react';
import './Page.scss';

const Page = (props) => {

  return (
    <div className={`page ${props.className}`}>
      {props.topContent && (
        <div className={`page__top`}>
          {props.topContent}
        </div>
      )}
      <div className={`page__content`}>
        <div className="container">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Page;
