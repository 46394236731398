import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Page from '../components/Page';
import './Message.scss';
import PageProgress from '../components/PageProgress';
import colorCodes from '../colorCodes';

const Message = (props) => {
  const history = useHistory();

  useEffect(() => {
    if (!props.name) {
      history.push('/');
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = () => {
    const randomColorIndex = Math.round(Math.random() * (colorCodes.length - 1));
    const randomColor = colorCodes[randomColorIndex];
    props.setSelectedColor(randomColor);
    history.push('/location');
  }

  return (
    <Page className={"message-screen"}>

      <div>
        <PageProgress currentScreen={1} totalScreens={3} />
      </div>

      <div className={"center"}>
        <h2>
          Thanks {props.name}! Do take some time to pen a message of love, hope and support for the LGBTQ community and its allies.
        </h2>
      </div>
      <div className={"center"}>
        <textarea
          placeholder={"Express your love and support, give a shoutout or share a sincere wish!"}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            props.setMessage(e.target.value);
          }}
          defaultValue={props.message}
          maxLength={140}
        />
      </div>
      <div className={"actions"}>
        {props.message && (
          <button onClick={handleNext}>Continue</button>
        )}
      </div>
      <div className={"actions__note"}>
        No profanity and hate-speech allowed. Your message will be viewable by anyone who clicks on your dot on the map.
      </div>
    </Page>
  );
};

export default Message;
