import React from 'react';
import "./Holding.scss";

const Holding: React.FC = () => {
  return (
    <div className="holding-screen">
      <div className="holding-screen-message">
        <h1>We have stopped<br/> accepting submissions</h1>
        <h2>Please check back at 9.30pm to see the reveal of the map, and to read the messages of love and support</h2>

        <div className="logo" />
      </div>
    </div>
  )
};

export default Holding;
